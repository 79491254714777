import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import { devices } from "../../settings";
import Button from "../buttons/Button";

const Section = styled.div`
    padding: 0px;
    color: white;
    
    @media ${devices.mobile} {
        padding: 10px;
    }  

    h2 {
        font-size: 25px;
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
        color: white;
    }
    p {
        margin-top: 0px;
    }
`

function DifferentSection(props) {
    return (
        <Section>
            <h2>
                Have you ever waited for a week or longer for a sign price?
            </h2>

            <p>
                You can now design and price your own sign at your leisure, from your 
                office or even at home after hours. If you have questions or need some 
                help, our 5 star customer service team will be there to answer your questions.
            </p>
            <p>
                Your time is valuable and chasing unprofessional sign companies to get 
                your sign project complete is ridiculous.
            </p>

            <Link to="/builder/?from=">
                <Button
                    color="red"
                    label="Get Your Price Now"
                />
            </Link>

            <br />
            <br />
        </Section>
    );
}

export default DifferentSection